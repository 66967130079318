<template>
  <div class="g-box g-resources-add-box" v-loading="loading">
    <div class="m-form-inline-mn" data-m="add">
      <el-form
        :model="ruleForm"
        class="demo-form-inline"
        size="small"
        :rules="rules"
        ref="ruleForm"
        label-width="180px"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="m-tt">基本信息</h2>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item label="资源名称" prop="title">
              <el-input
                v-model="ruleForm.title"
                placeholder="请输入资源名称"
                :readonly="isModeInfo"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" v-if="isBank">
            <el-form-item label="资源代码" prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入资源代码"
                :readonly="isModeInfo"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="isBank">
          <el-col :span="17">
            <el-form-item label="资源路径" prop="path">
              <el-input
                v-model="ruleForm.path"
                placeholder="请输入资源路径"
                :readonly="isModeInfo"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item label="客户端类型">
              <el-select
                v-model="ruleForm.clientType"
                placeholder="请选择客户端类型"
                :disabled="isModeInfo || isModeEdit"
                @change="switchClientType"
              >
                <el-option
                  v-for="item in filter.clientTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7" v-if="isBank">
            <el-form-item label="图标" prop="icon">
              <el-input
                v-model="ruleForm.icon"
                placeholder="请输入图标"
                :readonly="isModeInfo"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="序号" prop="sort">
              <el-input
                v-model="ruleForm.sort"
                placeholder="请输入序号"
                :readonly="isModeInfo"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="isBank">
          <el-col :span="7">
            <el-form-item label="资源分类" prop="type">
              <el-select
                v-model="ruleForm.position"
                placeholder="请选择资源分类"
                :disabled="isModeInfo || isModeEdit"
                @change="getMenu"
              >
                <el-option
                  v-for="item in filter.positionOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="资源类型" prop="type">
              <el-select
                v-model="ruleForm.type"
                placeholder="请选择资源类型"
                :disabled="isModeInfo || isModeEdit"
              >
                <el-option
                  v-for="item in filter.typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="isBank">
          <el-col :span="7" v-if="!propLoading">
            <el-form-item label="上级资源">
              <el-cascader
                v-model="ruleForm.prop"
                placeholder="请选择上级资源"
                :disabled="isModeInfo"
                :options="filter.propOptions"
                :props="{ checkStrictly: true }"
                clearable
                :show-all-levels="false"
                @change="propChange"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col
            :span="7"
            v-if="!ruleForm.prop.length && ruleForm.type !== 'btn'"
          >
            <el-form-item label="所属系统" prop="type">
              <el-select
                v-model="ruleForm.code"
                placeholder="请选择所属系统"
                :disabled="isModeInfo"
              >
                <el-option
                  v-for="item in systemOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :span="7"
            v-if="ruleForm.position === tool.getRoleId('TENANTUSER') && isBank"
          >
            <el-form-item label="推送园区" prop="type">
              <el-select
                v-model="ruleForm.push"
                placeholder="请选择推送园区"
                :disabled="isModeInfo"
                multiple
                collapse-tags
              >
                <el-option
                  v-for="item in filter.pushOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" v-if="isNavType">
            <el-form-item label="激活" prop="type">
              <el-switch
                v-model="ruleForm.show"
                :disabled="isModeInfo"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="17">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="ruleForm.remark"
                placeholder="请输入备注"
                :readonly="isModeInfo"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="m-btn-group">
      <el-button
        class="u-btn s-submit"
        size="small"
        type="primary"
        @click="submitForm('ruleForm')"
        v-if="!isModeInfo"
      >
        提交
      </el-button>
      <el-button
        class="u-btn s-cancel"
        size="small"
        v-if="isModeInfo"
        @click="$router.go(-1)"
      >
        返回
      </el-button>
      <el-button
        class="u-btn s-cancel"
        size="small"
        v-else
        @click="returnPreviousPage"
      >
        返回
      </el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import api from './../../../api'

export default Vue.extend({
  name: 'resources-add',
  components: {
  },
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      ruleForm: {
        title: '',
        name: '',
        show: true,
        path: '',
        type: 'nav',
        icon: '',
        sort: '0',
        code: 'ZEUS',
        prop: [],
        roleId: '',
        position: this.tool.getRoleId('BANKUSER'),
        remark: '',
        push: [],
        oPush: [],
        clientType: 'PC'
      },
      record: [],
      filter: {
        typeOptions: [{
          label: '导航',
          value: 'nav'
        }, {
          label: '自定义导航',
          value: 'customizeNav'
        }, {
          label: '按钮',
          value: 'btn'
        }],
        clientTypeOptions: [{
          label: '桌面',
          value: 'PC',
          system: ['ZEUS', 'POSEIDON', 'HADES', 'ARES', 'SELENE']
        }, {
          label: 'app',
          value: 'APP',
          system: ['SELENE_APP']
        }],
        propOptions: [],
        systemOptions: [{
          label: '权限中心',
          value: 'ZEUS'
        }, {
          label: '大屏配置化',
          value: 'POSEIDON'
        }, {
          label: '物联中台',
          value: 'HADES'
        }, {
          label: '工作流',
          value: 'ARES'
        }, {
          label: '智慧社区',
          value: 'SELENE'
        }, {
          label: '智慧社区app',
          value: 'SELENE_APP'
        }],
        positionOptions: [{
          label: '超管平台',
          value: this.tool.getRoleId('BANKUSER'),
          code: 'BANKUSER'
        }, {
          label: '园区平台',
          value: this.tool.getRoleId('TENANTUSER'),
          code: 'TENANTUSER'
        }],
        pushOptions: []
      },
      loading: true,
      propLoading: false,
      rules: {
        title: [
          { required: true, message: '请输入资源名称', trigger: 'blur' },
          { max: 15, message: '最多15位字符', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        name: [
          { required: true, message: '请输入资源代码', trigger: 'blur' },
          { max: 150, message: '最多150位字符', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        path: [
          { required: false, message: '请输入资源路径', trigger: 'blur' },
          { max: 150, message: '最多150位字符', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        icon: [
          { required: false, message: '请输入图标', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        sort: [
          { required: true, message: '请输入序号', trigger: 'blur' },
          { max: 8, message: '最多8位字符', trigger: 'blur' },
          { pattern: /^[0-9]\d*$/, message: '必须为非负整数', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        remark: [
          { required: false, message: '请输入备注', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ]
      }
    }
  },
  computed: {
    isModeAdd () {
      return this.$route.name === 'resources-add'
    },
    isModeEdit () {
      return this.$route.name === 'resources-edit'
    },
    isModeInfo () {
      return this.$route.name === 'resources-info'
    },
    isBank () {
      return VueCookie.get('role') === 'BANKUSER'
    },
    systemOptions () {
      return this.filter.systemOptions
        .filter(item => this.filter.clientTypeOptions
          .find(item => item.value === this.ruleForm.clientType).system.includes(item.value))
    },
    isNavType () {
      return this.ruleForm.type === 'nav' || this.ruleForm.type === 'customizeNav'
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.init()
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    init () {
      if (this.isModeAdd) {
        // api.auth.getAuthAllPark().then(res => {
        //   this.filter.pushOptions = res.data.map(item => {
        //     return {
        //       label: item.parkName,
        //       value: item.parkId
        //     }
        //   })
        // })
        this.getMenu()
        this.loading = false
      }

      if (this.isModeInfo || this.isModeEdit) {
        let pushOptions = []
        const getAllPark = api.auth.getAuthAllPark()
        const getInfo = api.menu.postMenuFindById({
          id: this.$route.params.id
        })
        Promise.all([getAllPark, getInfo]).then(result => {
          pushOptions = result[0].data.map(item => {
            return {
              label: item.parkName,
              value: item.parkId
            }
          })

          this.ruleForm.title = result[1].data.title
          this.ruleForm.name = result[1].data.name
          this.ruleForm.show = result[1].data.isShow
          this.ruleForm.path = result[1].data.path
          this.ruleForm.type = result[1].data.type
          this.ruleForm.icon = result[1].data.icon
          this.ruleForm.sort = result[1].data.sort + ''
          this.ruleForm.code = result[1].data.menuCode
          this.ruleForm.roleId = result[1].data.roleId
          this.ruleForm.remark = result[1].data.remark
          this.ruleForm.oPush = (result[1].data.parkIds || '').split(',')
          this.ruleForm.clientType = result[1].data.channel
          this.ruleForm.push = []
          api.config.postMenuBuildMenu({
            body: {
              channel: this.ruleForm.clientType,
              roleId: Number(this.$route.params.roleId)
            }
          }).then(res => {
            this.propLoading = true
            this.ruleForm.prop = []
            this.filter.propOptions = this.getTreeData(res.data)
            setTimeout(() => {
              this.propLoading = false
            })
            this.loading = false
            this.filter.pushOptions = pushOptions.filter(item => !(result[1].data.parkIds || '').split(',').map(item => Number(item)).includes(item.value))
            this.ruleForm.prop = (this.recursionFindProp(this.filter.propOptions, 'value', Number(result[1].data.parentId)) || []).map(item => item.value).reverse()
            this.ruleForm.position = Number(this.$route.params.roleId)
          })
        })
      }
    },
    switchClientType () {
      this.ruleForm.code = this.systemOptions[0].value
      this.getMenu()
    },
    getMenu () {
      this.loading = true
      api.config.postMenuBuildMenu({
        body: {
          channel: this.ruleForm.clientType,
          roleId: this.ruleForm.position
        }
      }).then(res => {
        this.propLoading = true
        this.ruleForm.prop = []
        this.filter.propOptions = this.getTreeData(res.data)
        setTimeout(() => {
          this.propLoading = false
        })
      }).finally(() => {
        this.loading = false
      })
    },
    propChange () {
      if (!this.ruleForm.prop.length) return false
      this.ruleForm.code = (this.recursionFind(this.filter.propOptions, 'value', this.ruleForm.prop[this.ruleForm.prop.length - 1]) || {}).code || 'ZEUS'
    },
    getTreeData (list = []) {
      return list.map(item => {
        return {
          value: item.id || '',
          label: item.title || '',
          code: item.code || '',
          children: item.node.length ? this.getTreeData(item.node) : null
        }
      })
    },
    recursionFind (tree = [], key = 'value', value = '') {
      for (let i = 0; i < tree.length; i++) {
        const item = tree[i]
        if (item[key] === value) {
          return item
        } else {
          if ((item.children || []).length) {
            const _value = this.recursionFind(item.children, key, value)
            if (_value) return _value
          }
        }
      }
    },
    getInfo (callback = () => { }) {
      api.menu.postMenuFindById({
        id: this.$route.params.id
      }).then(res => {
        this.ruleForm.title = res.data.title
        this.ruleForm.name = res.data.name
        this.ruleForm.show = res.data.isShow
        this.ruleForm.path = res.data.path
        this.ruleForm.type = res.data.type
        this.ruleForm.icon = res.data.icon
        this.ruleForm.sort = res.data.sort + ''
        this.ruleForm.code = res.data.menuCode
        this.ruleForm.roleId = res.data.roleId
        this.ruleForm.remark = res.data.remark
        this.ruleForm.oPush = (res.data.parkIds || '').split(',')
        this.ruleForm.clientType = res.data.channel
        this.ruleForm.push = []
        callback((res.data.parkIds || '').split(',').map(item => Number(item)))
      }).finally(() => {
        this.loading = false
      })
    },
    recursionFindProp (tree = [], key = 'value', value = '') {
      for (const i in tree) {
        if (tree[i].value === value) {
          return [tree[i]]
        }
        if (tree[i].children || []) {
          const node = this.recursionFindProp(tree[i].children, key, value)
          if (node !== undefined) {
            return node.concat(tree[i])
          }
        }
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) return false
        if (this.ruleForm.type === 'btn' && !this.ruleForm.prop.length && this.isBank) return this.$message.warning('资源类型为按钮时，上级资源为必填')
        if (this.isModeAdd) {
          api.menu.postMenuSaveOrUpdate({
            body: {
              channel: this.ruleForm.clientType,
              icon: this.ruleForm.icon,
              isShow: this.isNavType ? this.ruleForm.show : false,
              menuCode: this.ruleForm.code,
              name: this.ruleForm.name,
              type: this.ruleForm.type,
              parentId: this.ruleForm.prop[this.ruleForm.prop.length - 1],
              path: this.ruleForm.path,
              sort: this.ruleForm.sort,
              title: this.ruleForm.title,
              roleId: this.ruleForm.position,
              remark: this.ruleForm.remark,
              parkIds: this.ruleForm.position === this.tool.getRoleId('TENANTUSER') ? this.ruleForm.push.join(',') : '',
              parkIdLast: this.ruleForm.oPush.join(',')
            }
          }).then(() => {

            // this.$router.push('/authority-management/resources/resources-list?position=' + this.filter.positionOptions.find(item => item.value === this.ruleForm.position).code)
            this.$router.push('/authority-management/resources/resources-list')
          })
        }
        if (this.isModeEdit) {
          let body = {}
          if (this.isBank) {
            body = {
              id: this.$route.params.id,
              channel: this.ruleForm.clientType,
              icon: this.ruleForm.icon,
              isShow: this.isNavType ? this.ruleForm.show : false,
              menuCode: this.ruleForm.code,
              name: this.ruleForm.name,
              type: this.ruleForm.type,
              parentId: this.ruleForm.prop[this.ruleForm.prop.length - 1],
              path: this.ruleForm.path,
              sort: this.ruleForm.sort,
              title: this.ruleForm.title,
              roleId: this.ruleForm.position,
              remark: this.ruleForm.remark,
              parkIds: this.ruleForm.position === this.tool.getRoleId('TENANTUSER') ? this.ruleForm.push.join(',') : '',
              parkIdLast: this.ruleForm.oPush.join(',')
            }
          } else {
            body = {
              id: this.$route.params.id,
              isShow: this.ruleForm.show,
              sort: this.ruleForm.sort,
              title: this.ruleForm.title,
              remark: this.ruleForm.remark,
              parentId: this.ruleForm.prop[this.ruleForm.prop.length - 1],
              roleId: this.ruleForm.position
            }
          }
          api.menu.postMenuSaveOrUpdate({
            body
          }).then(() => {

            // this.$router.push('/authority-management/resources/resources-list?position=' + this.filter.positionOptions.find(item => item.value === this.ruleForm.position).code)
            this.$router.push('/authority-management/resources/resources-list')
          })
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
@import "./../../../assets/css/global-variable.scss";
.g-resources-add-box {
  position: relative;
  overflow: auto;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
}
.m-tt {
  margin-bottom: 30px;
  font-size: $fs-title;
  color: $fc-primary;
  font-weight: 500;
  line-height: 18px;
  text-indent: 10px;
  border-left: 4px solid $fc-theme;
  &.s-no-top {
    margin-top: 30px;
  }
}
.u-tree {
  float: left;
  min-width: 200px;
}
</style>

<style>
</style>
